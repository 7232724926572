
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Fragment, HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import useSWR from 'swr';
import { LandingPageQuery, PictureFieldsFragment, PictureType } from 'generated/graphql';
import { Carousel } from 'src/components/carousel';
import { ImageWide } from 'src/components/common/image-wide';
import ContainerGrid from 'src/components/container-grid';
import ContentContainerBanner from 'src/components/content-container-banner';
import { FaqList } from 'src/components/faq-list';
import HeadTags from 'src/components/head-tags';
import InViewLink from 'src/components/in-view-link';
import LandingPageFooter from 'src/components/landing-page-footer';
import LiveConcertCountdown from 'src/components/live-concert/live-concert-countdown';
import MarketingDevices from 'src/components/marketing/marketing-devices';
import Page from 'src/components/page';
import RenderClientOnly from 'src/components/render-client-only';
import ScrollDownHint from 'src/components/scroll-down-hint';
import SignupCtaButton from 'src/components/signup-cta-button';
import SubscriptionPlans from 'src/components/subscription-plans';
import { Teaser } from 'src/components/teaser/teaser';
import { TeaserImage } from 'src/components/teaser/teaser-image';
import useInterval from 'src/hooks/use-interval';
import useReducedMotion from 'src/hooks/use-reduced-motion';
import useSdk from 'src/hooks/use-sdk';
import useTranslate from 'src/hooks/use-translate';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';
import { EmptyPageParameters } from 'src/types';
import { Faq, getFaqs } from 'src/utilities/get-faqs';
import { getCurrentLocale } from 'src/utilities/i18n-helpers';
import { getPictureByType } from 'src/utilities/image-helpers';
import { cloudinaryLoaderWide } from 'src/utilities/image-sdk';
import { incrementalServerSideWithQuery } from 'src/utilities/ssr-helpers';
import { getNodePath } from 'src/utilities/url-helpers';
// the time it takes for one slide to travel from right edge to the left one
const popularNowCarouselSlideDuration = 14000;
const newAlbumsCarouselSlideDuration = 4000;
// Width of an album cover in a slider or desktop
const sliderAlbumWidth = 160;
// Gap between album covers in a slider on desktop
const sliderAlbumGap = 30;
// Desktop classes for album cover in a slider
const sliderAlbumClassNamesLg = 'lg:mr-[30px] lg:w-[160px]';
// Number of items we request for server side rendering
const sliderItemCountServerSide = 11;
// Returns number of items we need to fetch to cover the current screen
const getSliderItemCount = () => typeof window === 'undefined'
    ? undefined
    : Math.max(Math.ceil(window?.screen.width / (sliderAlbumWidth + sliderAlbumGap)), sliderItemCountServerSide);
type LandingPageProps = {
    fallbackData: LandingPageQuery;
    faqs: Faq[];
};
// Type definition of a Slider type returned by the landing page query
type LandingPageSlider = Extract<LandingPageQuery['curatedContent']['curatedContent'][number], {
    __typename: 'ListSlider';
}>;
// Type definition of a LiveConcert type returned by the landing page query
type LandingPageLiveConcert = Extract<LandingPageSlider['items'][number], {
    __typename: 'LiveConcert';
}>;
type LandingPagePopularNow = Extract<LandingPageSlider['items'][number], {
    __typename: 'VodConcert';
}>;
type LandingPageNewAlbums = Extract<LandingPageSlider['items'][number], {
    __typename: 'Album';
}>;
/**
 * The live feature section of the landing page.
 * Displays the next upcoming live concert, a countdown clock, image, and a button to sign up.
 */
function LandingPageLiveModule({ liveConcerts, ...rootHtmlElementAttributes }: HTMLAttributes<HTMLDivElement> & {
    liveConcerts: LandingPageLiveConcert[];
}) {
    const t = useTranslate();
    const [upcomingLiveConcert, setUpcomingLiveConcert] = useState<LandingPageLiveConcert>();
    const upcomingLiveConcertPicture = upcomingLiveConcert?.pictures && getPictureByType(upcomingLiveConcert.pictures, PictureType.Teaser);
    // Find the next upcoming live concert and set it as the state
    const updateUpcomingLiveConcert = useCallback(() => {
        const now = Date.now();
        const upcomingLiveConcert = liveConcerts.find((liveConcert) => {
            const liveConcertDate = new Date(liveConcert.streamStartTime || liveConcert.startTime);
            return liveConcertDate.getTime() > now;
        });
        setUpcomingLiveConcert(upcomingLiveConcert);
    }, [liveConcerts]);
    // Update the upcoming live concert every second
    useInterval(updateUpcomingLiveConcert, 1000);
    return (
    // Render client-side only because the server and client may have different times
    <RenderClientOnly>
      {upcomingLiveConcert ? (<div data-test="landing-page-live-module" {...rootHtmlElementAttributes}>
          <ContainerGrid>
            <LandingPageLiveModulePicture upcomingLiveConcert={upcomingLiveConcert} upcomingLiveConcertPicture={upcomingLiveConcertPicture} className="hidden lg:block" role="presentation"/>
            <div className="col-span-full self-center text-center lg:col-span-6">
              <h2 className="dg-text-medium-2 mb-8 lg:mb-10">{t('page__landing_live_headline')}</h2>
              <LandingPageLiveModulePicture upcomingLiveConcert={upcomingLiveConcert} upcomingLiveConcertPicture={upcomingLiveConcertPicture} className="lg:hidden"/>
              <div className="mb-3 flex justify-center md:mb-5">
                <LiveConcertCountdown liveConcert={upcomingLiveConcert}/>
              </div>
              <p className="dg-text-light-1 mb-6 lg:mb-8 2xl:mb-12">{upcomingLiveConcert.title}</p>
            </div>
          </ContainerGrid>
        </div>) : (<Fragment />)}
    </RenderClientOnly>);
}
function LandingPageLiveModulePicture({ upcomingLiveConcertPicture, upcomingLiveConcert, role, className, }: {
    upcomingLiveConcertPicture: PictureFieldsFragment | undefined;
    upcomingLiveConcert: LandingPageLiveConcert;
    role?: string;
    className?: string;
}) {
    return (<div className={clsx('relative col-span-full mb-8 overflow-hidden rounded lg:order-2 lg:col-span-6 lg:mb-0', className)} role={role}>
      {upcomingLiveConcertPicture && (<ImageWide alt={upcomingLiveConcert.title} src={upcomingLiveConcertPicture.url} loader={cloudinaryLoaderWide} 
        // suggested srcSet for the image based on sizes defined in the imageSizes|deviceSizes array in next.config.js
        sizes="(min-width: 1280px) 1080px, (min-width: 1024px) 512px, 100vw" fill/>)}
    </div>);
}
function SliderLink({ href, root, children }: {
    href: string;
    root: Element | null;
    children: ReactNode;
}) {
    return (<InViewLink href={href} prefetch={false} className="block focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-brandYellowC1" observerOptions={{
            // We want to make the links active only when they are inside the slider
            // viewport, regardless of the page scrolling position
            // 1. Get a handle of a slider container (Carousel component)
            root,
            // 2. Trigger intersection only when at least 75% of a slide item is
            //    within the slider viewport
            threshold: 0.75,
            // 3. Only take into account horizontal overlap, ignore vertical overlap
            rootMargin: '100% 0% 100% 0%'
        }}>
      <Teaser>{children}</Teaser>
    </InViewLink>);
}
const dgLogoSource = 'images/dg-logo.png';
function ExclusiveContent({ popularNowItems, newAlbumItems, }: {
    popularNowItems: LandingPagePopularNow[];
    newAlbumItems: LandingPageNewAlbums[];
}) {
    const t = useTranslate();
    const popularNowCarouselRef = useRef<HTMLDivElement>(null);
    const newAlbumsCarouselRef = useRef<HTMLDivElement>(null);
    return (<div className="pb-20 pt-12" data-test="landing-page-exclusive-content">
      <h1 className="dg-text-medium-2 md:dg-text-medium-1 mx-auto mb-5 max-w-2xl text-center md:mb-4">
        {t('marketing_exlcusive_content__access')}
      </h1>
      <p className="dg-text-medium-3 md:dg-text-regular-5 mx-auto mb-[60px] max-w-2xl px-9 text-center font-light opacity-70">
        {t('marketing_exlcusive_content__explore')}
      </p>
      <div className="mb-[34px]">
        <Carousel ref={popularNowCarouselRef} 
    // Negative margin + padding to make focus outlines visible inside the slider
    mainContainerClassName="-my-1 py-1" itemsContainerClassName="items-top" itemSlideDuration={popularNowCarouselSlideDuration} data-test="landing-page-popular-now-carousel">
          {popularNowItems.map((item) => (<div key={item.id} className="mr-4 w-[190px] lg:mr-10 lg:w-[408px]">
              <SliderLink href={getNodePath(item)} root={popularNowCarouselRef?.current}>
                <div className="mb-2">
                  <TeaserImage src={getPictureByType(item.pictures, PictureType.Teaser)?.url} variant="wide" playable darken alt={item.title}/>
                </div>
                <div className="font-light text-sm opacity-55 lg:text-xl">{item.title}</div>{' '}
              </SliderLink>
            </div>))}
        </Carousel>
      </div>

      <Carousel 
    // Negative margin + padding to make focus outlines visible inside the slider
    mainContainerClassName="-my-1 py-1" itemsContainerClassName="items-top" itemSlideDuration={newAlbumsCarouselSlideDuration} data-test="landing-page-new-albums-carousel">
        {newAlbumItems.map((item) => (<div className={`mr-4 w-[89px] ${sliderAlbumClassNamesLg}`} key={item.id}>
            <SliderLink href={getNodePath(item)} root={newAlbumsCarouselRef?.current}>
              <TeaserImage src={getPictureByType(item.pictures, PictureType.Cover)?.url} variant="square" playable darken alt={item.title}/>
            </SliderLink>
          </div>))}
      </Carousel>
    </div>);
}
/** The video loop in the background */
function HeroVideo() {
    const ref = useRef<HTMLVideoElement>(null);
    const reducedMotion = useReducedMotion();
    useEffect(() => {
        // Don't autoplay video when the user refers reduced motion
        if (reducedMotion) {
            ref.current?.pause();
        }
        else {
            void ref.current?.play();
        }
    }, [reducedMotion]);
    return (<div className="absolute inset-0 transform-gpu bg-mainBgBlueC2" aria-hidden data-test="video-background-container">
      <video 
    // Suppress hydration warning because we always add `preload="none"`
    // during server side rendering, which can be removed on the client when
    // the reduced motion is off
    suppressHydrationWarning ref={ref} 
    // Don't preload the video file on reduced motion, only show the poster image
    preload={reducedMotion ? 'none' : undefined} loop muted playsInline width="100%" height="100%" className="absolute inset-0 h-full object-cover fade-to-b" poster="https://res.cloudinary.com/dg-stage/image/upload/f_auto,q_auto/v1/web/onboarding-video-wide-placeholder_cccjvo">
        <source src="https://res.cloudinary.com/dg-stage/video/upload/v1649665781/web/onboarding-video-wide_ol6rof.mp4"/>
      </video>
    </div>);
}
/**
 * Full width hero with video and registration button
 */
function Hero() {
    const t = useTranslate();
    // The scroll marker is used to mark the start of the content that is below
    // the header
    const scrollMarkerRef = useRef<HTMLDivElement>(null);
    return (<>
      <div className="main-area relative flex flex-col justify-between" style={{ minHeight: '32rem' }}>
        <HeroVideo />
        <ContainerGrid>
          <div className="relative col-span-full">
            <div className="absolute left-0 top-12 h-[67px] w-[120px] lg:h-[101px] lg:w-[184px]">
              <Image alt={t('page__landing_hero_logo_label')} src={dgLogoSource} unoptimized fill/>
            </div>
          </div>
        </ContainerGrid>
        <ContainerGrid>
          <div className="relative col-span-full py-24 text-center lg:mb-[3vw] xl:py-28">
            <h1 className="dg-text-medium-1 mb-2 2xl:mb-4">{t('page__landing_hero_headline')}</h1>
            <p className="dg-text-regular-5 mb-4 opacity-70 2xl:mb-8">{t('page__landing_hero_description')}</p>
            <TrackingContext.Provider value={TrackingContextValues.LandingPageHero}>
              <SignupCtaButton />
            </TrackingContext.Provider>
          </div>
        </ContainerGrid>
        <div className="absolute bottom-2 left-1/2 -ml-4">
          <ScrollDownHint refToScrollTo={scrollMarkerRef}/>
        </div>
      </div>
      {/* A click on ScrollDownHint will scroll this element to the top of the screen  */}
      <div ref={scrollMarkerRef}></div>
    </>);
}
/**
 * Landing page showing the main features of the app. This page is only shown to non-authenticated users.
 */
function LandingPage({ fallbackData, faqs }: LandingPageProps) {
    const t = useTranslate();
    const sdk = useSdk();
    const { data } = useSWR('landing-page', () => sdk.landingPage({ sliderItemsFirst: getSliderItemCount() }), {
        fallbackData
    });
    // Select the banner
    const banner = useMemo(() => {
        const bannerNode = data.curatedContent.curatedContent.find((contentContainer) => contentContainer.__typename === 'Banner');
        // If the banner is not found, return null, otherwise confirm the type so TypeScript knows it's a Banner
        return bannerNode && bannerNode.__typename === 'Banner' ? bannerNode : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "Next Live Concerts" by item type "Live Concert"
    const liveConcerts = useMemo(() => {
        if (!data?.curatedContent.curatedContent)
            return [];
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'LiveConcert';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPageLiveConcert[]) : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "Popular Now" slider by item type "VODConcert"
    const popularNowItems = useMemo(() => {
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'VodConcert';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPagePopularNow[]) : null;
    }, [data?.curatedContent.curatedContent]);
    // Select the "New Albums" slider by item type "Album"
    const newAlbumsItems = useMemo(() => {
        const slider = data.curatedContent.curatedContent.find((container) => {
            return container.__typename === 'ListSlider' && container.items[0].__typename === 'Album';
        });
        return slider && slider.__typename === 'ListSlider' ? (slider.items as LandingPageNewAlbums[]) : null;
    }, [data?.curatedContent.curatedContent]);
    const subscriptionPlans = data?.subscriptionPlansStripe;
    return (<Page testId="landing-page">
      <HeadTags title={t('page__landing_title')}/>

      <Hero />

      {/* Optional Banner */}
      {banner && <ContentContainerBanner banner={banner}/>}

      {/* Live Module */}
      {liveConcerts && <LandingPageLiveModule liveConcerts={liveConcerts} className="pb-20 pt-12"/>}

      {/* Exclusive Content */}
      {popularNowItems && newAlbumsItems ? (<ExclusiveContent popularNowItems={popularNowItems} newAlbumItems={newAlbumsItems}/>) : null}

      {/* Ticket Module */}
      {subscriptionPlans && (<div className="pb-24 pt-12 2xl:pb-32">
          <ContainerGrid>
            <TrackingContext.Provider value={TrackingContextValues.LandingPageSubscriptionPlans}>
              <div className="col-span-full md:col-span-8 md:col-start-3 xl:col-span-6 xl:col-start-4">
                <SubscriptionPlans subscriptionPlans={subscriptionPlans.plans} titleTranslationKey="page__landing_subscribe"/>
              </div>
            </TrackingContext.Provider>
          </ContainerGrid>
        </div>)}

      {/* Devices Module */}
      <MarketingDevices />

      {/* FAQs */}
      {faqs.length > 0 && (<>
          <h2 className="dg-text-medium-1 mb-14 text-center md:mb-12">{t('marketing_devices__faqs')}</h2>
          <ContainerGrid>
            <div className="col-span-full pb-16 md:pb-20 2xl:col-span-8 2xl:col-start-3">
              <FaqList faqs={faqs}/>
            </div>
          </ContainerGrid>
        </>)}
    </Page>);
}
LandingPage.footerComponent = LandingPageFooter;
export default LandingPage;
const getStaticProps = incrementalServerSideWithQuery<EmptyPageParameters, LandingPageProps>(async ({ context, sdk }) => {
    const fallbackData = await sdk.landingPage();
    const currentLocale = getCurrentLocale(context.locale);
    const faqs = await getFaqs({ locale: currentLocale });
    return {
        props: { fallbackData, faqs },
        revalidate: 3600
    };
});

    async function __Next_Translate__getStaticProps__1916f7c4512__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1916f7c4512__ as getStaticProps }
  