import { SignupLink } from 'src/components/auth-link';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';

type SignupCtaButtonProps = {
  /**
   * Optional custom translation key for the button text.
   * @default 'button__signup_cta'
   */
  translationKey?: TranslationKeyCommon;
};

/**
 * A styled `SignupLink` component in the form of a button with tracking.
 */
export default function SignupCtaButton({ translationKey = 'button__signup_cta' }: SignupCtaButtonProps) {
  const t = useTranslate();

  return (
    <SignupLink dataTest="signup-cta-button" buttonType="primary" className="lg:px-10" trackingTextId={translationKey}>
      {t(translationKey)}
    </SignupLink>
  );
}
